import React, { useState, useEffect } from "react";

import axios from "axios";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ScanSelection from "../components/ScanSelection";
import ConnectionAlert from "../components/ConnectionAlert";
import Scanners from "../components/scanners/scanners";
import {
  Code as ParcelCode,
  IOfflineScan,
  SCANNER_TYPE,
  Settings,
  USER_TYPE,
  regexpLabel,
  LabelParseException,
} from "pcd_library";
import UIfx from "../lib/uifx";
import { submitParcel } from "../lib/http/warehouse";
import Alert from "react-bootstrap/Alert";
import LabelCheckAlert from "../components/LabelCheckAlert";
import { useLogger } from "../lib/logger";

export enum AppStages {
  ReadIn,
  Review,
  Submit,
}

export type CollectProps = {
  isOnline: boolean;
  pendingScans: IOfflineScan[];
  appSettings: Settings;
  onLabelSent: (success: boolean, code: ParcelCode | null) => void;
  onRequestScanSendOnline: (code: ParcelCode | null) => void;
  scanFx: UIfx;
  errorFx: UIfx;
};

const Collect: React.FC<CollectProps> = (props) => {
  const { logger } = useLogger();
  const { isOnline, pendingScans, appSettings } = props;

  const [scanType, setScanType] = useState<SCANNER_TYPE>(appSettings.ScanType);
  const [currentStage, setCurrentStage] = useState<AppStages>(AppStages.ReadIn);
  const [currentParcelError, setCurrentParcelError] = useState<string | null>(
    null
  );

  useEffect(() => {
    logger.debug("Section: Entered Collect");
    return () => logger.debug("Section: Exited Collect");
  }, [logger]);

  useEffect(() => {
    if (currentStage === AppStages.Submit) {
      const timeout = setTimeout(() => {
        setCurrentStage(AppStages.ReadIn);
      }, 750);

      return () => clearTimeout(timeout);
    }
  }, [currentStage]);

  const doSubmit = (parcel: ParcelCode) => {
    return new Promise((resolveSubmit) => {
      if (!isOnline) {
        props.onRequestScanSendOnline(parcel);
        return resolveSubmit(true);
      }

      const source = axios.CancelToken.source();

      submitParcel(parcel.headerId, parcel.sub, source.token)
        .then(() => {
          resolveSubmit(true);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            return;
          }

          if (thrown.response) {
            logger.error("Collect: Failed to submit parcel label", {
              response: thrown.response,
            });

            switch (thrown.response.status) {
              case 400:
              case 401:
              case 409:
                setCurrentParcelError(thrown.response.data.message || "");
                break;
            }
          } else {
            if (!thrown.status) {
              logger.debug("Collect: Generic issue on submit", {
                error: thrown,
              });
              // generic network issue
              props.onRequestScanSendOnline(parcel);
            }
          }

          resolveSubmit(false);
        });
    });
  };

  const processLabel = (label?: string) => {
    setCurrentParcelError(null);

    if (currentStage !== AppStages.ReadIn) {
      return;
    }

    if (!label) {
      return;
    }

    try {
      const parcel = ParcelCode.parseLabel(label);

      props.scanFx.play();

      setCurrentStage(AppStages.Review);

      doSubmit(parcel)
        .then(() => setCurrentStage(AppStages.Submit))
        .catch(() => setCurrentStage(AppStages.ReadIn));
    } catch (e) {
      let message = "Unable to process label";
      if (e instanceof LabelParseException) {
        logger.error(`Collect: Failed to parse label: ${e.message}`, {
          name: e.name,
          stack: e.stack,
        });
        message = e.message;
      }
      props.errorFx.play();
      setCurrentStage(AppStages.ReadIn);
      setCurrentParcelError(message);
    }
  };

  return (
    <>
      <ConnectionAlert isConnected={isOnline} pendingScans={pendingScans} />
      {currentParcelError && (
        <Alert variant="danger">
          {currentParcelError
            .replace("[", "<strong>")
            .replace("]", "</strong>")}
        </Alert>
      )}
      {currentStage > AppStages.ReadIn && (
        <LabelCheckAlert isWorking={currentStage === AppStages.Review} />
      )}
      <Row className="justify-content-center">
        <Col xs="12">
          <Scanners
            scanType={scanType}
            facingMode={appSettings.FacingMode}
            onRead={(type, label) => processLabel(label)}
            userType={USER_TYPE.Driver}
            manualPattern={regexpLabel.source}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <ScanSelection
            scanner={scanType}
            onChange={(newVal) => {
              appSettings.ScanType = newVal;
              setScanType(newVal);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Collect;
