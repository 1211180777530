import React, { FC, createContext, useContext, useRef } from "react";
import { IndexLogger, LogFunction, Logger, getLogFile } from "pcd_library";

function downloadBlob(blob: Blob, filename: string) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement("a");

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || "download";

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener("click", clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

const downloadLogs = async () => {
  const date = new Date();
  return downloadBlob(
    await getLogFile(),
    `logs-${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}-${date.getHours()}_${date
      .toLocaleTimeString()
      .replace("/:/g", "-")}.log`
  );
};

export type LoggerContextType = {
  logger: Logger;
  download: () => Promise<HTMLAnchorElement>;
  getLog: () => Promise<Blob>;
};

const logNoOp: LogFunction = (message) => {
  console.error("Logger not setup yet", message);
};

export const LoggerContext = createContext<LoggerContextType>({
  logger: {
    error: logNoOp,
    warn: logNoOp,
    info: logNoOp,
    debug: logNoOp,
    setContext: () => {},
    wipe: () => Promise.resolve(),
  },
  download: downloadLogs,
  getLog: getLogFile,
});

console.debug("Create logger instance");
const loggerInstance = new IndexLogger("App");
loggerInstance.setContext({ version: "0.2.6" });

export const LoggerProvider: FC<{ children?: React.ReactNode }> = (props) => {
  const logger = useRef(loggerInstance);

  return (
    <LoggerContext.Provider
      value={{
        logger: logger.current,
        download: downloadLogs,
        getLog: getLogFile,
      }}
    >
      {props.children}
    </LoggerContext.Provider>
  );
};

export const useLogger = () => useContext(LoggerContext) as LoggerContextType;

export const logger = loggerInstance;
