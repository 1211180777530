import { IndexLogger } from "./IndexLogger";
import RecordStore, {
  ScanState,
  IOfflineTripDetails,
  IOfflineTripParcel,
} from "./RecordStore";

export enum ServiceWorkerSync {
  SendScans = "send-collection",
  SendDriverParcels = "send-driver-parcels",
  SendDriverTrip = "send-driver-trips",
  SendCourierParcels = "send-courier-parcels",
  SendCourierTrip = "send-courier-trips",
  SendDropParcels = "send-drop-parcels",
}

export default class ServiceWorkerActions {
  private recordStore: RecordStore;
  private api: string;
  private myToken: string;
  private logger: IndexLogger;

  public ready: Promise<boolean>;

  constructor(apiUrl: string) {
    this.logger = new IndexLogger("ServiceWorker");
    this.logger.setContext({ version: "0.2.6" });

    this.recordStore = new RecordStore(this.logger);
    this.api = apiUrl;
    this.myToken = "";
    this.ready = new Promise((resolve, reject) => {
      this.loadToken()
        .then(() => resolve(true))
        .catch(() => reject(false));
    });

    this.ready
      .then(() => this.logger.debug("Readied up"))
      .catch(() => {
        this.logger.debug("Failed to ready up");
      });
  }

  set token(value: string) {
    this.logger.info("Set token called");
    this.myToken = value;
    this.storeToken();
  }

  private storeToken() {
    const token = this.myToken;
    return this.recordStore
      .writeServiceWorkerOption("jwt-key", token)
      .then(() => this.logger.info("Stored token", { token }));
  }

  private loadToken() {
    return this.recordStore
      .readServiceWorkerOption("jwt-key")
      .then(([result]) => {
        if (result) {
          this.logger.info("Loaded token value", { value: result.value });
          this.myToken = result.value;
        }
      });
  }

  log(message: string) {
    this.logger.info(`SW-Action: ${message}`);
  }

  error(message: string, error?: Error) {
    this.logger.error(`SW-Action: ${message}`, {
      error,
    });
  }

  sendAllPendingScans() {
    const requestTime = Date.now();
    return new Promise<void>((topResolve, topReject) => {
      this.logger.debug("Send All Pending Scans called", { requestTime });
      try {
        this.recordStore
          .claimPendingScanRecords()
          .then((scans) => {
            if (!scans || (scans && scans.length === 0)) {
              this.logger.info("Nothing to do A-OK!", { requestTime });
              topResolve();
              return;
            }

            this.logger.info("Found scans to send", {
              count: scans.length,
              requestTime,
            });

            Promise.all(
              scans.map(async (scan) => {
                try {
                  const response = await fetch(`${this.api}collection`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + this.myToken,
                    },
                    referrerPolicy: "no-referrer",
                    body: JSON.stringify({
                      headerId: scan.headerId,
                      sub: scan.sub,
                    }),
                  });

                  if (response.ok) {
                    this.logger.info("Sent to server successfully", {
                      requestTime,
                    });
                    this.recordStore.updateScan(scan.id, {
                      state: ScanState.Sent,
                      submittedAt: new Date(),
                    });
                  } else {
                    const responseJSON = await response.json();
                    this.logger.error("Failed to submit parcel label", {
                      response: responseJSON,
                      requestTime,
                    });
                    let message = "An error occured during submission";
                    switch (responseJSON.statusCode) {
                      case 400:
                      case 401:
                      case 404:
                      case 409:
                        message = responseJSON.message || message;
                        break;
                    }
                    this.recordStore.updateScan(scan.id, {
                      state: ScanState.Failed,
                      message,
                      responseStatus: responseJSON.statusCode,
                      submittedAt: new Date(),
                    });
                  }
                } catch (error) {
                  this.logger.error("Fatal error sending scans", {
                    error: JSON.stringify(error),
                    requestTime,
                  });
                  // Network connectivity issue
                  this.recordStore
                    .releaseSendingScanRecords()
                    .finally(() => topReject());
                }
              })
            ).then(() => {
              this.logger.debug("Sent all scans", { requestTime });
              topResolve();
            });
          })
          .catch(() => {
            this.logger.error("Failed to get pending records", { requestTime });
            topReject();
          });
      } catch {
        this.logger.error("Generic problem happened", { requestTime });
        topReject();
      }
    });
  }

  sendAllPendingTrips() {
    return new Promise<void>((topResolve, topReject) => {
      this.logger.info("Trips: start sending");
      try {
        this.recordStore
          .claimPendingTripRecords()
          .then((trips) => {
            if (!trips) {
              this.logger.info("Trips: Nothing to do A-OK!");
              topResolve();
              return;
            }

            Promise.all(
              trips.map(async (trip: IOfflineTripDetails) => {
                try {
                  const response = await fetch(
                    `${this.api}driver/trip/${trip.externalId}/arrived`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.myToken,
                      },
                      referrerPolicy: "no-referrer",
                      body: JSON.stringify({
                        arrivedAt: trip.scannedAt,
                      }),
                    }
                  );
                  const [responseJSON, isOK] = await Promise.all([
                    response.json(),
                    response.ok,
                  ]);
                  if (isOK) {
                    this.logger.info("Sent to server successfully");
                    this.recordStore.updateTrip(trip.id, {
                      state: ScanState.Sent,
                      submittedAt: new Date(),
                    });
                  } else {
                    this.logger.error("Failed to submit parcel label", {
                      response: responseJSON,
                    });
                    let message = "An error occured during submission";
                    switch (responseJSON.statusCode) {
                      case 400:
                      case 401:
                      case 404:
                      case 409:
                        message = responseJSON.message || message;
                        break;
                    }
                    this.recordStore.updateTrip(trip.id, {
                      state: ScanState.Failed,
                      message,
                      responseStatus: responseJSON.statusCode,
                      submittedAt: new Date(),
                    });
                  }
                } catch (error) {
                  this.logger.error("Fatal error sending pending trips", {
                    error: JSON.stringify(error),
                  });
                  // Network connectivity issue
                  this.recordStore
                    .releaseSendingTripRecords()
                    .finally(() => topReject());
                }
              })
            ).then(() => topResolve);
          })
          .catch((error) => {
            this.logger.error("Failed to get pending records", { error });
            topReject();
          });
      } catch {
        this.logger.error(
          "Generic problem happened whilst sending pending trips"
        );
        topReject();
      }
    });
  }

  sendAllPendingDriverTripParcels() {
    this.sendTripParcels(`${this.api}driver/parcel/scanned`);
  }

  sendAllPendingCourierTripParcels() {
    this.sendTripParcels(`${this.api}courier/parcel/scanned`);
  }

  sendAllPendingDropParcels() {
    return new Promise<void>((topResolve, topReject) => {
      this.logger.info("Drop Parcels: Starting send");
      try {
        this.recordStore
          .claimPendingDropParcelRecords()
          .then((parcels) => {
            if (!parcels) {
              this.logger.info("Drop Parcels: Nothing to do A-OK!");
              topResolve();
              return;
            }

            Promise.all(
              parcels.map(async (parcel: IOfflineTripParcel) => {
                try {
                  const response = await fetch(
                    `${this.api}driver/parcel/dropped/${parcel.dropPoint}`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.myToken,
                      },
                      referrerPolicy: "no-referrer",
                      body: JSON.stringify({
                        scannedAt: parcel.scannedAt,
                        qrcode: parcel.qrcode,
                        sub: parcel.sub,
                      }),
                    }
                  );

                  if (response.ok) {
                    this.recordStore.updateDropParcel(parcel.id, {
                      state: ScanState.Sent,
                      submittedAt: new Date(),
                    });
                  } else {
                    const responseJSON = await response.json();
                    this.logger.error("Failed to submit parcel label", {
                      response: responseJSON,
                    });
                    let message = "An error occured during submission";
                    switch (responseJSON.statusCode) {
                      case 400:
                      case 401:
                      case 404:
                      case 409:
                        message = responseJSON.message || message;
                        break;
                    }
                    this.recordStore.updateDropParcel(parcel.id, {
                      state: ScanState.Failed,
                      message,
                      responseStatus: responseJSON.statusCode,
                      submittedAt: new Date(),
                    });
                  }
                } catch (error) {
                  this.logger.error("Fatal error sending all pending drops", {
                    error,
                  });
                  // Network connectivity issue
                  this.recordStore
                    .releaseSendingDropParcelRecords()
                    .finally(() => topReject());
                }
              })
            ).then(() => topResolve);
          })
          .catch((error) => {
            this.logger.error("Failed to get pending records", { error });
            topReject();
          });
      } catch {
        this.logger.error(
          "Generic problem happened whilst sending pending trips"
        );
        topReject();
      }
    });
  }

  private sendTripParcels(targetUrl: string) {
    return new Promise<void>((topResolve, topReject) => {
      this.logger.info("Parcels: Starting sent");
      try {
        this.recordStore
          .claimPendingTripParcelRecords()
          .then((parcels) => {
            if (!parcels) {
              this.logger.info("Parcels: Nothing to do A-OK!");
              topResolve();
              return;
            }

            Promise.all(
              parcels.map(async (parcel: IOfflineTripParcel) => {
                try {
                  const response = await fetch(targetUrl, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + this.myToken,
                    },
                    referrerPolicy: "no-referrer",
                    body: JSON.stringify({
                      scannedAt: parcel.scannedAt,
                      qrcode: parcel.qrcode,
                      sub: parcel.sub,
                    }),
                  });

                  if (response.ok) {
                    this.recordStore.updateParcel(parcel.id, {
                      state: ScanState.Sent,
                      submittedAt: new Date(),
                    });
                  } else {
                    const responseJSON = await response.json();
                    this.logger.error("Failed to submit parcel label", {
                      response: responseJSON,
                    });
                    let message = "An error occured during submission";
                    switch (response.status) {
                      case 400:
                      case 401:
                      case 404:
                      case 409:
                        message = responseJSON.message || message;
                        break;
                    }

                    this.logger.warn("Error during sending trip parcels", {
                      responseContent: responseJSON,
                      status: response.status,
                    });

                    this.recordStore.updateParcel(parcel.id, {
                      state: ScanState.Failed,
                      message,
                      responseStatus: responseJSON.statusCode,
                      submittedAt: new Date(),
                    });
                  }
                } catch (error) {
                  this.logger.error("Fatal error sending trip parcels", {
                    error: JSON.stringify(error),
                  });
                  // Network connectivity issue
                  this.recordStore
                    .releaseSendingTripParcelRecords()
                    .finally(() => topReject());
                }
              })
            ).then(() => topResolve());
          })
          .catch((error) => {
            this.logger.error("Failed to get pending records", { error });
            topReject();
          });
      } catch {
        this.logger.error(
          "Generic problem happened whilst sending pending trips"
        );
        topReject();
      }
    });
  }
}
