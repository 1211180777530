import React, { useState, useEffect, useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Scanners from "../components/scanners/scanners";
import ScanSelection from "../components/ScanSelection";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  USER_TYPE,
  IOfflineTripParcel,
  SCANNER_TYPE,
  Code,
  ScanState,
  IOfflineDropPoint,
  regexpLabel,
  Settings,
} from "pcd_library";
import { ScanStateAction } from "../lib/types/scan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import { useParams, Link, Prompt } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import DateFormatter from "../lib/dateFormatter";
import { FetchState } from "../lib/http/common";
import UIfx, { FX_PLAY, RETURN_TO_SCAN } from "../lib/uifx";
import { useLogger } from "../lib/logger";

export type DeliverParcelProps = {
  isOnline: boolean;
  appSettings: Settings;
  userType: USER_TYPE;
  parcels: IOfflineTripParcel[];
  dropPoints: IOfflineDropPoint[];
  dropParcelScanned: (
    parcel: Code,
    isUnknown?: boolean,
    dropPoint?: string,
    includeAll?: boolean
  ) => void;
  loadDropDetails: (droppoint: string) => void;
  fetchParcels: FetchState;
  scanFx: UIfx;
  errorFx: UIfx;
  specialErrorFx: UIfx;
  activeDrop: (dropid: string) => void;
};

type DeliverParams = {
  dropId?: string;
};

const DeliverParcel: React.FC<DeliverParcelProps> = ({
  isOnline,
  appSettings,
  parcels,
  userType,
  dropPoints,
  dropParcelScanned,
  fetchParcels,
  errorFx,
  scanFx,
  specialErrorFx,
  loadDropDetails,
  activeDrop,
}) => {
  const params = useParams<DeliverParams>();
  //const history = useHistory();

  const { logger } = useLogger();

  const [scanType, setScanType] = useState<SCANNER_TYPE>(appSettings.ScanType);
  const [currentScanError, setCurrentScanError] = useState<string | null>(null);
  const [showScanned, setShowScanned] = useState<boolean>(false);
  const [currentDrop] = useState<string>(params.dropId || "");
  const [currentDropPoint, setCurrentDropPoint] =
    useState<IOfflineDropPoint | null>(null);

  const [shownItems, setShownItems] = useState<IOfflineTripParcel[]>([]);
  const [scanState, setScanState] = useState<ScanStateAction>(
    ScanStateAction.Scanning
  );
  const [queryParcel, setQueryParcel] = useState<Code | null>(null);
  const [isDropComplete, setIsDropComplete] = useState<boolean>(false);

  const [pendingParcelCount, setPendingParcelCount] = useState(parcels.length);

  const [scanBuff, setScanBuff] = useState<string[]>([]);

  useEffect(() => {
    logger.debug("Section: Entered DeliverParcel");
    return () => logger.debug("Section: Exited DeliverParcel");
  }, [logger]);

  useEffect(() => {
    const found = dropPoints.find((drop) => drop.drop === currentDrop);
    if (found && found !== currentDropPoint) {
      setCurrentDropPoint(found);
    } else if (!found) {
      setCurrentDropPoint(null);
    }
  }, [currentDrop, currentDropPoint, dropPoints]);

  useEffect(
    () => {
      if (currentDrop) {
        loadDropDetails(currentDrop);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDrop]
  );

  useEffect(() => {
    if (scanState === ScanStateAction.Complete) {
      setTimeout(() => setScanState(ScanStateAction.Scanning), RETURN_TO_SCAN);
    }
  }, [scanState]);

  useEffect(() => {
    const dropIds = dropPoints
      .filter(
        (drop) => drop.drop === currentDrop || drop.parent === currentDrop
      )
      .map((drop) => drop.drop);

    let pendingItems = false;
    let pendingCount = 0;
    setShownItems(
      parcels.filter((parcel) => {
        if (!parcel.scannedAt) {
          pendingItems = true;
          ++pendingCount;
        }
        return (
          dropIds.indexOf(parcel.dropPoint) !== -1 &&
          (showScanned || !parcel.scannedAt)
        );
      })
    );

    setPendingParcelCount(pendingCount);

    if (pendingItems === isDropComplete) {
      setIsDropComplete(!pendingItems);
    }
  }, [
    currentDrop,
    currentDropPoint,
    dropPoints,
    showScanned,
    parcels,
    isDropComplete,
  ]);

  useEffect(() => {
    activeDrop(isDropComplete ? "" : currentDrop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropComplete, currentDrop]);

  useEffect(() => {
    // required initial push to enable the block
    window.history.pushState(null, document.title, document.URL);

    const listener = function () {
      window.history.pushState(null, document.title, document.URL);
    };

    window.addEventListener("popstate", listener);

    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, []);

  const processLabel = useCallback(
    (label?: string) => {
      if (!label) {
        return;
      }

      if (scanState !== ScanStateAction.Scanning) {
        logger.warn("DeliverParcel: Added scan to buffer", [label, scanBuff]);
        setScanBuff((buff: string[]) => buff.concat([label]));
        return;
      }

      performance.mark("startScan");

      setScanState(ScanStateAction.Read);
      setCurrentScanError(null);

      performance.mark("stateSet");

      console.log(performance.measure("initScan", "startScan", "stateSet"));

      try {
        const parcel = Code.parseLabel(label);

        performance.mark("labelParsed");

        console.log(performance.measure("parsing", "stateSet", "labelParsed"));

        const found = parcels.find(
          (fparcel) =>
            fparcel.qrcode === parcel.headerId && fparcel.sub === parcel.sub
        );

        performance.mark("parcelSearch");
        console.log(
          performance.measure("parcelSeek", "labelParsed", "parcelSearch")
        );

        if (!found) {
          logger.warn("Scanned an unexpected parcel", { label, parcel });
          specialErrorFx.play();
          setQueryParcel(parcel);
          setScanState(ScanStateAction.Scanning);
        } else {
          scanFx.play();
          performance.mark("soundPlay");
          console.log(
            performance.measure("notify", "parcelSearch", "soundPlay")
          );
          if (!found.scannedAt) {
            // report scan to app
            dropParcelScanned(parcel);
            performance.mark("report");
            console.log(performance.measure("reported", "soundPlay", "report"));
          }

          setTimeout(() => {
            setScanState(ScanStateAction.Complete);
            performance.mark("complete");
            console.log(
              performance.measure("scanComplete", "startScan", "complete")
            );
          }, FX_PLAY);
        }
      } catch (e) {
        errorFx.play();
        setScanState(ScanStateAction.Scanning);
        // @ts-ignore
        setCurrentScanError(e.message);
      }
    },
    [
      errorFx,
      parcels,
      scanBuff,
      scanFx,
      scanState,
      dropParcelScanned,
      specialErrorFx,
      logger,
    ]
  );

  const listIcon = (parcel: IOfflineTripParcel | IOfflineDropPoint) => {
    let result;

    if (!parcel.scannedAt) {
      result = <FontAwesomeIcon icon={["far", "square"]} size="lg" />;
    } else if (parcel.scannedAt && !parcel.submittedAt) {
      result = (
        <FontAwesomeIcon
          className="text-warning"
          icon="check-square"
          size="lg"
        />
      );
    } else if (parcel.state === ScanState.Failed) {
      result = (
        <FontAwesomeIcon
          className="text-danger"
          icon="times-circle"
          size="lg"
        />
      );
    } else if (parcel.state === ScanState.Sent) {
      result = (
        <FontAwesomeIcon
          className="text-success"
          icon="check-square"
          size="lg"
        />
      );
    }

    return result;
  };

  const listOutParcels = (parcels: IOfflineTripParcel[], total: number) => {
    if (parcels.length === 0) {
      const message =
        total > 0 ? "All items delivered" : "No items for delivery";
      const variant = total > 0 ? "success" : "info";

      return (
        <ListGroup className="mt-2">
          <ListGroup.Item className="text-center" variant={variant}>
            <Row>
              <Col xs="12">{message}</Col>
              <Col xs="12">
                <Link
                  to={{
                    pathname: "/trip-summary",
                  }}
                >
                  <Button variant="success">Next Delivery</Button>
                </Link>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      );
    }

    const contents = parcels.map((record) => (
      <ListGroup.Item key={`${record.id}_${record.qrcode}`}>
        <Row>
          <Col xs="5">
            Parcel {record.qrcode}-{record.sub}
          </Col>
          <Col xs="5">
            {record.scannedAt
              ? `Scanned in (${DateFormatter.humanReadable(record.scannedAt)})`
              : "Not Scanned"}
          </Col>
          <Col xs="2">{listIcon(record)}</Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  useEffect(() => {
    if (scanState === ScanStateAction.Scanning) {
      logger.debug("DeliverParcel: Returned to scanning, check buffer");
      if (scanBuff.length > 0) {
        logger.debug("DeliverParcel: Item in buffer, sending to scanner");
        processLabel(scanBuff[0]);
        setScanBuff((buff) => buff.slice(1));
      }
    }
  }, [scanState, scanBuff, processLabel, logger]);

  const getFormattedDropPoint = (drop: IOfflineDropPoint | null): string => {
    if (!drop) {
      return "";
    }

    return `(${drop.name ? drop.name : drop.postcode})`;
  };

  if (fetchParcels < FetchState.Received) {
    return (
      <>
        <Alert variant="success">
          <Spinner animation="border" size="sm" />
          &nbsp;
          <span>Loading drop details</span>
        </Alert>
      </>
    );
  }

  return (
    <>
      <Prompt
        when={pendingParcelCount !== 0}
        message={`Not all parcels for this drop have been scanned (${pendingParcelCount} to be scanned), are you sure you wish to leave this delivery incomplete?`}
      />
      {!isOnline && (
        <Alert variant="warning">
          You are not currently connected to the internet, items scanned will be
          recorded when you reconnect
        </Alert>
      )}
      <Alert variant="info">
        Please scan all parcels listed for
        {currentDropPoint ? <strong> {currentDropPoint.name}</strong> : ""}
      </Alert>
      <Row className="justify-content-center">
        {currentScanError && (
          <Col xs="auto">
            <Alert variant="danger">{currentScanError}</Alert>
          </Col>
        )}
        {scanState !== ScanStateAction.Scanning && (
          <Col xs="auto">
            <Alert
              variant={
                scanState === ScanStateAction.Complete ? "success" : "warning"
              }
            >
              {scanState !== ScanStateAction.Complete && (
                <>
                  <Spinner size="sm" animation="border" />
                  <span>Checking label</span>
                </>
              )}
              {scanState === ScanStateAction.Complete && (
                <>
                  <FontAwesomeIcon
                    className="text-success"
                    icon="check-square"
                    size="lg"
                  />
                  <span>Label scanned</span>
                </>
              )}
            </Alert>
          </Col>
        )}
        {!queryParcel && (
          <>
            <Col xs="12">
              <Scanners
                scanType={scanType}
                facingMode={appSettings.FacingMode}
                onRead={(type, label) => processLabel(label)}
                userType={userType}
                manualPattern={regexpLabel.source}
              />
            </Col>
            <Col xs="auto">
              <ScanSelection
                onChange={(newType) => {
                  appSettings.ScanType = newType;
                  setScanType(newType);
                }}
                scanner={scanType}
              />
            </Col>
          </>
        )}
        {queryParcel && (
          <Col xs="12">
            <Alert variant="warning">
              This is not an expected parcel for this location{" "}
              {getFormattedDropPoint(currentDropPoint)}
              <div className="d-flex justify-content-center mt-2">
                <Button onClick={() => setQueryParcel(null)}>Understood</Button>
              </div>
            </Alert>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs="12">&nbsp;</Col>
      </Row>
      <Row>
        <Col xs="6">
          <Form>
            <Form.Check
              label="Show scanned items"
              checked={showScanned}
              onChange={() => setShowScanned(!showScanned)}
              id="show-processed-items-loadup"
            />
          </Form>
        </Col>
        <Col xs="6" className="text-right">
          Remaining: {pendingParcelCount}/{parcels.length}
        </Col>
      </Row>
      <Row>
        <Col xs="12">{listOutParcels(shownItems, parcels.length)}</Col>
      </Row>
    </>
  );
};

export default DeliverParcel;
